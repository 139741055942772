import React, { useState, useEffect } from 'react'; // Import useState and useEffect
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppBar, Toolbar, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Table, TableRow, TableCell, Select, MenuItem, TableHead, TableBody, Grid, ButtonGroup } from '@mui/material';
import AlarmIcon from '@mui/icons-material/Alarm';
import '@fontsource/montserrat'; // Importing the Montserrat font
import CssBaseline from '@mui/material/CssBaseline';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { jsPDF } from 'jspdf';
import { FaFilePdf } from 'react-icons/fa';

const theme = createTheme({
  typography: {
    fontFamily: '"Montserrat", Arial, sans-serif',
  },
  palette: {
    primary: { main: '#008080' },
    secondary: { main: '#FF6B6B' },
  },
});

function ToDoApp() {

  // Initialize categories from localStorage if available
  const [categories, setCategories] = useState(() => {
    const savedCategories = localStorage.getItem('categories');
    return savedCategories ? JSON.parse(savedCategories) : [];
  });

  // Initialize tasks from localStorage if available
  const [tasks, setTasks] = useState(() => {
    const savedTasks = localStorage.getItem('tasks');
    return savedTasks ? JSON.parse(savedTasks) : [];
  });


    // For Categories
    const [open, setOpen] = React.useState(false);
    const [categoryName, setCategoryName] = React.useState('');
    const [importance, setImportance] = React.useState(1);
    // const [categories, setCategories] = React.useState([]);

    // For Tasks
    const [openTask, setOpenTask] = React.useState(false);
    const [selectedCategory, setSelectedCategory] = React.useState('');
    const [taskName, setTaskName] = React.useState('');
    const [taskImportance, setTaskImportance] = React.useState(1);
    const [timeNeeded, setTimeNeeded] = React.useState('');
    const [deadline, setDeadline] = React.useState(new Date());
    // const [tasks, setTasks] = React.useState([]);
    // State to hold the estimated time
    const [estimatedTime, setEstimatedTime] = useState('');

    // Function to fetch estimated time from the backend
    const handleEstimateTime = async () => {
        console.log('handleEstimateTime called');
        if (!taskName) {
            console.error('Task name is required for estimating time.');
            return;
        }
    
        try {
            const data = {
                taskDescription: taskName
            };
    
            const response = await axios.post('http://localhost:3001/estimate-time', data);
    
            // Update state with the estimated time directly from the response
            // You will need to adjust this based on the actual structure of your response
            setEstimatedTime(response.data.estimatedTime);
        } catch (error) {
            console.error('Error estimating time:', error);
            // Handle the error accordingly in your UI
        }
    };

    // Use useEffect to call the handleEstimateTime function when the component mounts
    // or when certain states change, depending on your requirements
    useEffect(() => {
        // Call handleEstimateTime here if you want to fetch estimated time automatically
        // handleEstimateTime();
    }, [taskName]); // Add dependencies array if needed, like taskName to fetch every time it changes

    // Load tasks and categories from localStorage when the component mounts
    useEffect(() => {
        setTasks(loadFromLocalStorage('tasks', []));
        setCategories(loadFromLocalStorage('categories', []));
    }, []);
    
    // Save tasks and categories to localStorage whenever they change
    useEffect(() => {
        saveToLocalStorage('tasks', tasks);
    }, [tasks]);
    
    useEffect(() => {
        saveToLocalStorage('categories', categories);
    }, [categories]);
    
    // New state for editing category importance
    const [editingCategoryIndex, setEditingCategoryIndex] = React.useState(null);

    // Handlers for Categories
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleCommit = () => {
        const newCategory = { name: categoryName, importance: importance };
        const updatedCategories = [...categories, newCategory].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setCategories(updatedCategories);
        setCategoryName('');
        setImportance(1);
        handleClose();
        saveToLocalStorage('categories', updatedCategories);
    };

    // Handlers for Tasks
    const handleOpenTask = () => {
        console.log("handleOpenTask called");
        setOpenTask(true);
    };

    const handleCloseTask = () => {
        setOpenTask(false);
    };

    const handleCommitTask = () => {
        console.log('Committing task with timeNeeded:', timeNeeded); // Log the timeNeeded before creating the task
    
        // Define timeToSet before logging it
        const timeToSet = timeNeeded || estimatedTime || '0'; // Use timeNeeded if available, otherwise use estimatedTime, if both are not available, default to '0'
    
        console.log('estimatedTime:', estimatedTime); // Log the estimatedTime to see its value
        console.log('Time to set for the task:', timeToSet); // Log the time that will be set for the task
    
        const newTask = { 
            category: selectedCategory,
            name: taskName, 
            importance: taskImportance,
            time: timeToSet, // Set the time for the task
            deadline: deadline
        };
    
        console.log('New task object:', newTask); // Log the new task object to see all its properties
    
        const updatedTasks = [...tasks, newTask];
        setTasks(updatedTasks); // Update the state with the new list of tasks
    
        // Log the tasks after updating
        console.log('Tasks after update:', updatedTasks);
    
        // Reset the form fields
        setSelectedCategory('');
        setTaskName('');
        setTaskImportance(1);
        setTimeNeeded('');
        setDeadline(new Date());
        handleCloseTask();
        saveToLocalStorage('tasks', updatedTasks);
    };

// adding the buttons
    const markTaskAsCompleted = (taskIndex) => {
        const updatedTasks = [...tasks];
        updatedTasks[taskIndex].completed = !updatedTasks[taskIndex].completed; // Toggle completion status
        setTasks(updatedTasks);
        saveToLocalStorage('tasks', updatedTasks);
    };
    
    const deleteTask = (taskIndex) => {
        const updatedTasks = tasks.filter((_, index) => index !== taskIndex);
        setTasks(updatedTasks);
        saveToLocalStorage('tasks', updatedTasks);
    };

    const deleteCategoryWithTasks = (categoryName) => {
        // Filter out the category to be deleted
        const updatedCategories = categories.filter(category => category.name !== categoryName);
        setCategories(updatedCategories);
    
        // Filter out the tasks that belong to the deleted category
        const updatedTasks = tasks.filter(task => task.category !== categoryName);
        setTasks(updatedTasks);
        saveToLocalStorage('categories', updatedCategories);
        saveToLocalStorage('tasks', updatedTasks);
    };

    // Handlers for editing category importance
    const handleStartEditingImportance = (index) => {
        setEditingCategoryIndex(index);
    };

    const handleStopEditingImportance = () => {
        setEditingCategoryIndex(null);
    };

    // Date formatting handler
    const formatDateForInput = (date) => {
        let dd = date.getDate();
        let mm = date.getMonth() + 1; // January is 0!
        const yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        const hh = String(date.getHours()).padStart(2, '0');
        const min = String(date.getMinutes()).padStart(2, '0');
        return yyyy + '-' + mm + '-' + dd + 'T' + hh + ':' + min;
    };

// SCHEDULER SECTION

// State to hold the generated schedule
const [schedule, setSchedule] = useState([]);
// State to track if the schedule has been generated
const [scheduleGenerated, setScheduleGenerated] = useState(false);

    // Function to generate schedule
    const generateSchedule = async () => {
        try {
            // For now, I'll just log them to the console
            console.log('Categories:', categories);
            console.log('Tasks:', tasks);

            // Prepare the data to be sent
            const dataToSend = {
                categories: categories.map(category => ({
                    name: category.name,
                    importance: category.importance,
                })),
                tasks: tasks.map(task => ({
                    name: task.name,
                    importance: task.importance,
                    timeNeeded: task.timeNeeded,
                    deadline: task.deadline,
                })),
            };

            // Send the data to the server
            const response = await fetch('http://localhost:3001/generate-schedule', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSend),
            });

            // Handle the response
            const generatedSchedule = await response.json();
            console.log(generatedSchedule);

            // Update the schedule state
            setSchedule(generatedSchedule.schedule); // Access the 'schedule' property of the response
            setScheduleGenerated(true); // Set this to true to indicate the schedule has been generated
        } catch (error) {
            console.error('Error generating schedule:', error);
            // Handle the error accordingly in your UI
            setScheduleGenerated(false); // Set this to false if there was an error
        }
    };

  // PDF DONWLOADER

    const downloadPdf = () => {
        // Create a new jsPDF instance
        const doc = new jsPDF();
    
        // Set the font for the document
        doc.setFont('helvetica', 'normal');
    
        // Optionally set the font size
        doc.setFontSize(12);
    
        // Define colors
        const headerFillColor = '#4F81BD'; // A nice shade of blue
        const contentFillColor = '#D9E1F2'; // A lighter shade of blue
        const textColor = '#000000'; // Black for text
    
        // Add a title with background color
        doc.setFillColor(headerFillColor);
        doc.rect(20, 20, doc.internal.pageSize.width - 40, 10, 'F'); // x, y, width, height, style
        doc.setTextColor('#FFFFFF'); // White text color
        doc.text('Schedule', 25, 27); // x, y
    
        // Reset text color for the rest of the content
        doc.setTextColor(textColor);
    
        // Split the schedule string into lines
        const lines = doc.splitTextToSize(schedule, doc.internal.pageSize.width - 60); // Adjust width as needed
    
        // Add the lines to the document one by one with alternating background colors
        lines.forEach((line, index) => {
        const lineY = 40 + (index * 10); // Calculate the Y position of the line
    
        // Alternate the background color
        if (index % 2 === 0) {
            doc.setFillColor(contentFillColor);
            doc.rect(20, lineY - 5, doc.internal.pageSize.width - 40, 10, 'F'); // x, y, width, height, style
        }
    
        // Add the text over the colored box
        doc.text(line, 25, lineY); // x, y
        });
    
        // Save the PDF with the name 'schedule.pdf'
        doc.save('schedule.pdf');
    };
  
    // SAVE AND LOAD FUNCTION
    // Save data to localStorage
    const saveToLocalStorage = (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    };
    
    // Load data from localStorage
    const loadFromLocalStorage = (key, defaultValue) => {
        const storedData = localStorage.getItem(key);
        return storedData ? JSON.parse(storedData) : defaultValue;
    };















    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h5" fontWeight="bold" sx={{ flexGrow: 1 }}>
                        {`{USER}'s ToDo List 🐶`}
                    </Typography>

                    <Button 
                        color="inherit" 
                        sx={{
                            backgroundColor: theme.palette.secondary.main,
                            margin: 1,
                            transition: "background 0.4s",
                            "&:hover": {
                                background: "radial-gradient(circle, white, transparent)"
                            },
                            "&:active": {
                                background: "white"
                            }
                        }}
                        onClick={handleOpen}
                    >
                        Add Category
                    </Button>
                    <Button 
                        color="inherit" 
                        sx={{
                            backgroundColor: theme.palette.secondary.main,
                            margin: 1,
                            transition: "background 0.4s",
                            "&:hover": {
                                background: "radial-gradient(circle, white, transparent)"
                            },
                            "&:active": {
                                background: "white"
                            }
                        }}
                        onClick={handleOpenTask}
                    >
                        Add Task
                    </Button>
                </Toolbar>
            </AppBar>
                        
            {/* New Task Dialog */}
            <Dialog open={openTask} onClose={handleCloseTask} maxWidth="sm" fullWidth>
                <DialogTitle>Add Task</DialogTitle>
                <DialogContent>
                    <Select 
                        value={selectedCategory} 
                        onChange={e => setSelectedCategory(e.target.value)} 
                        fullWidth
                        style={{ marginBottom: '20px' }}
                    >
                        {categories.map(category => (
                            <MenuItem key={category.name} value={category.name}>
                                {category.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <TextField 
                        label="Task Name" 
                        value={taskName} 
                        onChange={e => setTaskName(e.target.value)} 
                        fullWidth 
                        style={{ marginBottom: '20px' }}
                    />
                    <Typography variant="h6" style={{ marginBottom: '10px', fontWeight: 500 }}>
                        Importance <AlarmIcon />
                    </Typography>
                    <Select 
                        value={taskImportance} 
                        onChange={e => setTaskImportance(e.target.value)} 
                        fullWidth
                        style={{ marginBottom: '20px' }}
                    >
                        {[1,2,3,4,5].map(num => <MenuItem key={num} value={num}>{num}</MenuItem>)}
                    </Select>

                    <TextField 
                        label="Time needed (hours)" 
                        value={timeNeeded || estimatedTime} // Use estimatedTime as a fallback value
                        onChange={e => {
                            console.log('Before validation:', e.target.value); // Log the raw input value
                            if (e.target.value === '' || /^\d*\.?\d*$/.test(e.target.value)) {
                                console.log('Valid input. Updating timeNeeded:', e.target.value); // Log the validated input
                                setTimeNeeded(e.target.value);
                            }
                        }}
                        type="number"
                        fullWidth 
                        style={{ marginBottom: '20px' }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: "0.1", // Allow decimal values with one digit after the decimal point, adjust as needed
                        }}
                    />

                    <Button
                        style={{ marginBottom: '20px' }}
                        onClick={handleEstimateTime} // Attach the function to onClick event
                    >
                        Estimate Time 🧠
                    </Button>
                    <TextField
                        label="Deadline"
                        type="datetime-local"
                        value={formatDateForInput(deadline)}
                        onChange={e => setDeadline(new Date(e.target.value))}
                        fullWidth
                        style={{ marginBottom: '20px' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseTask}>Cancel</Button>
                    <Button onClick={handleCommitTask}>Commit</Button>
                </DialogActions>
            </Dialog>

         {/*THIS IS WHERE THE USER DEFINES WHAT PRINTS OUT FOR THE CATEGORY */}

            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle style={{ paddingBottom: '10px' }}>Add Category</DialogTitle>
                <DialogContent>
                    <TextField 
                        label="Category Name" 
                        value={categoryName} 
                        onChange={e => setCategoryName(e.target.value)} 
                        fullWidth 
                        style={{ marginBottom: '20px' }}
                    />
                    <Typography 
                        variant="h6" 
                        style={{ marginBottom: '10px', fontWeight: 500 }}
                    >
                        Importance <AlarmIcon />
                    </Typography>
                    <Select 
                        value={importance} 
                        onChange={e => setImportance(e.target.value)} 
                        fullWidth
                    >
                        {[1,2,3,4,5].map(num => <MenuItem key={num} value={num}>{num}</MenuItem>)}
                    </Select>
                </DialogContent>
                <DialogActions style={{ marginTop: '20px' }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleCommit}>Commit</Button>
                </DialogActions>
            </Dialog>


                {/* DEFINE WHAT GETS SENT TO THE TASK DISPLAY */} 
                {/* THIS IS THE CATEGORY INFO  */}
                <div style={{ padding: '20px' }}>
                    {categories.map((category, index) => (
                        <div key={`category-${category.name}-${index}`} style={{ marginBottom: '30px' }}>
                            <Grid container alignItems="center" spacing={2} justifyContent="center">
                                <Grid item xs={4}>
                                    <Typography variant="h5" fontWeight="bold" style={{ textTransform: 'uppercase' }}>
                                        {category.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                                    <AlarmIcon fontSize="small" style={{ marginRight: '5px' }} />
                                    {editingCategoryIndex === index ? (
                                        <ClickAwayListener onClickAway={handleStopEditingImportance}>
                                            <Select 
                                                value={category.importance}
                                                onChange={e => {
                                                    const updatedCategories = [...categories];
                                                    updatedCategories[index].importance = e.target.value;
                                                    setCategories(updatedCategories);
                                                }}
                                                style={{ width: '80px' }}
                                            >
                                                {[1,2,3,4,5].map(num => <MenuItem key={num} value={num}>{num}</MenuItem>)}
                                            </Select>
                                        </ClickAwayListener>
                                    ) : (
                                        <Button onClick={() => handleStartEditingImportance(index)}>
                                            <Typography variant="h5">
                                                {category.importance}
                                            </Typography>
                                        </Button>
                                    )}
                                </Grid>
                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button color="secondary" onClick={() => deleteCategoryWithTasks(category.name)}>
                                        <DeleteIcon />
                                    </Button>
                                    <Button 
                                        color="primary" 
                                        onClick={() => {
                                            setSelectedCategory(category.name);
                                            handleOpenTask();
                                        }}
                                    >
                                        <AddIcon />
                                    </Button>
                                </Grid>
                            </Grid>


                        {/* // TASK INFO THAT IS PRINTED */}

                        <Table>
                            <TableHead>
                                {/* You can add table headers here if needed */}
                            </TableHead>
                            <TableBody>
                                {tasks.filter(task => task.category === category.name).map((task, taskIndex) => (
                                    <TableRow key={`task-${task.name}-${taskIndex}`} style={{ textDecoration: task.completed ? 'line-through' : 'none' }}>
                                        <TableCell align="left">{task.name}</TableCell>
                                        <TableCell>
                                            <Select
                                                value={task.importance}
                                                onChange={e => {
                                                    const updatedTasks = [...tasks];
                                                    const taskToUpdate = updatedTasks.find(t => t.name === task.name && t.category === category.name);
                                                    if (taskToUpdate) {
                                                        taskToUpdate.importance = e.target.value;
                                                    }
                                                    setTasks(updatedTasks);
                                                }}
                                            >
                                                {[1,2,3,4,5].map(num => <MenuItem key={num} value={num}>{num}</MenuItem>)}
                                            </Select>
                                        </TableCell>

                                        <TableCell>
                                            <Typography component="span">
                                            Time Allocated: {task.time !== undefined && task.time !== '0' && task.time !== '' ? `${task.time} hours` : 'Not specified'}
                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography component="span" style={{ fontWeight: 'bold' }}>
                                                Deadline:
                                            </Typography>{' '}
                                            {task.deadline.toLocaleString()} 
                                            <Button>⌛</Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button onClick={() => markTaskAsCompleted(taskIndex)}>
                                                <CheckCircleOutlineIcon />
                                            </Button>
                                            <Button onClick={() => deleteTask(taskIndex)}>
                                                <DeleteIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                    </div>
                ))}
            </div>


            <div style={{ textAlign: 'center', padding: '20px' }}>
                <ButtonGroup color="primary" variant="contained">
                    <Button>1</Button>
                    <Button>2</Button>
                    <Button>3</Button>
                </ButtonGroup>
            </div>


            {/* Button to generate schedule */}
            <div style={{ textAlign: 'center', padding: '20px' }}>
                <ButtonGroup
                color="primary"
                variant="contained"
                >
                <Button onClick={generateSchedule}>Schedule Maker</Button>    
                </ButtonGroup>
            </div>


            {/* Conditionally display the generated schedule */}
        {scheduleGenerated && (
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h2 style={{ marginRight: '10px' }}>Schedule</h2>
                    <button onClick={downloadPdf} style={{ display: 'flex', alignItems: 'center' }}>
                        <FaFilePdf size={20} style={{ marginRight: '5px' }} />
                        Download as PDF
                    </button>
                </div>
                
                <div style={{
                    whiteSpace: 'pre-wrap',
                    background: '#f4f4f4',
                    padding: '15px',
                    borderRadius: '8px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    maxWidth: '60%', // Set max width to 60% of the parent container
                    margin: 'auto', // This will center the div
                    textAlign: 'left' // Align text to the left
                }}>
                    {schedule}
                </div>
            </div>
        )}


        </ThemeProvider>
    );
}

export default ToDoApp;
